
import regl from 'regl';
import { AppIntro } from '../api/src/models/AppIntro';
import { shaders } from '../lib/action-card-background';
import uiMixin from '../mixins/ui';

const INTRO_STEP_ANIM_MS = 2000;

const STEP_SHAPE_BACKDROP_WIDTH = 250;
const STEP_SHAPE_BACKDROP_HEIGHT = 60;
const SHADER = 'mouseRot';

export default {
  name: 'IntroView',
  mixins: [
    uiMixin,
  ],
  data() {
    return {
      stepActiveIndex: -1,
      stepActiveTextIndex: -1,
      isStepRevealed: false,
      introStyle: {
        '--intro-step-anim-s': `${Math.floor(INTRO_STEP_ANIM_MS / 1000)}s`,
        '--intro-step-shape-width': `${STEP_SHAPE_BACKDROP_WIDTH}px`,
        '--intro-step-shape-height': `${STEP_SHAPE_BACKDROP_HEIGHT}px`,
      },
      stepShapeBackdropWidth: STEP_SHAPE_BACKDROP_WIDTH,
      stepShapeBackdropHeight: STEP_SHAPE_BACKDROP_HEIGHT,
    };
  },
  computed: {
    steps() {
      return Object.values(AppIntro.Step);
    },
    stepsReverse() {
      const s = [...this.steps];
      s.reverse();
      return s;
    },
    stepActive() {
      return this.steps[this.stepActiveIndex];
    },
    isStepTextValid() {
      return !!this.stepActive && Array.isArray(this.stepActive.text);
    },
    stepProgress() {
      let p = 0;

      if (this.isStepTextValid && this.isStepRevealed) {
        p = ((this.stepActiveTextIndex + 1) / this.stepActive.text.length) * 100;
      }

      return p;
    },
  },
  watch: {
    stepActiveIndex() {
      if (this.stepActiveIndex >= this.steps.length) {
        // requests auth
        this.$store.commit('ui/didViewIntro');
      }
    }
  },
  created() {
    this._reglInstance = undefined;
  },
  mounted() {
    this.$store.commit('ui/allowForegroundHiddenOnSceneWatch', false);
    this.handleNextStep(true);
  },
  beforeDestroy() {
    this.removeShader();
  },
  methods: {
    getText(t) {
      return t.replace('%appName%', this.appName);
    },
    handleNextStepText() {
      setTimeout(() => {
        this.stepActiveTextIndex += 1;

        if (this.isStepTextValid && this.stepActiveTextIndex < this.stepActive.text.length - 1) {
          this.handleNextStepText();
        }
      }, INTRO_STEP_ANIM_MS);
    },
    handleNextStep(skipWait = false) {
      this.isStepRevealed = false;
      const p = ((this.stepActiveIndex + 1) / this.steps.length) * 100;
      this.$root.$emit('scene-demo-to', p);

      // allow time for hide animation
      setTimeout(() => {
        this.removeShader();

        this.stepActiveIndex += 1;
        this.stepActiveTextIndex = 0;
        this.isStepRevealed = true;

        this.$nextTick(() => {
          this.initShader();
        });

        this.handleNextStepText();
      }, skipWait ? 100 : INTRO_STEP_ANIM_MS);
    },
    handleSkip() {
      this.stepActiveIndex = this.steps.length;
    },
    initShader() {
      if (!this.stepActive?.shapes.find(s => s.type === AppIntro.ShapeType.enum.Backdrop)) {
        return;
      }

      // $refs returns an array because the elements are within a v-for loop
      const glCanvasArr = this.$refs[`gl-canvas-${this.stepActiveIndex}-0`];
      const glCanvas = Array.isArray(glCanvasArr) ? glCanvasArr[0] : undefined;

      if (this._reglInstance || !glCanvas) {
        return;
      }

      const fragmentShader = shaders[SHADER].frag;
      const start = `// #version 300 es
      precision mediump float;

      uniform vec2 iResolution;
      uniform vec4 iMouse;
      uniform float iTime;
      uniform float rand;
      uniform float importanceMultiplier;
      uniform float completeness;
      `;

      const end = `
      void main() {
        mainImage(gl_FragColor.rgba, gl_FragCoord.xy);
      }
      `;

      this._reglInstance = regl(glCanvas);
      const draw = this._reglInstance({
        frag: `${start}${fragmentShader}${end}`,

        vert: `
        precision mediump float;
        attribute vec2 position;
        varying vec2 uv;
        void main () {
          uv = position;
          gl_Position = vec4(2.0 * position - 1.0, 0, 1);
        }`,

        // This tells regl the number of vertices to draw in this command
        count: 3,

        attributes: {
          position: [
            -2, 0,
            0, -2,
            2, 2]
        },

        uniforms: {
          iResolution: [STEP_SHAPE_BACKDROP_WIDTH, STEP_SHAPE_BACKDROP_HEIGHT],
          iMouse: [0, 0, 0, 0],
          iTime: ({ tick }) => 0.01 * tick,
          rand: this._reglInstance.prop('rand'),
        },
      });

      const rand = Math.random();

      this._reglInstance.frame(() => {
        // clear contents of the drawing buffer
        this._reglInstance.clear({
          color: [0, 0, 0, 0],
          depth: 1
        })

        draw({
          rand,
        });
      });
    },
    removeShader() {
      if (this._reglInstance) {
        this._reglInstance.destroy();
      }
    },
  },
};
