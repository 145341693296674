import _ from 'lodash';
import { z } from 'zod';
import { App } from './App';

export namespace AppIntro {
  export const StepName = z.enum([
    'WeAreWhatWeDo',
    'Intro',
    'ActionsPlan',
    'Actions',
    'AddAnAction',
    'Value',
    'Auth',
  ]);

  export const ShapeType = z.enum([
    'Invisible',
    'Oval',
    'Diamond',
    'Rectangle',
    'Backdrop',
  ]);

  const action = App.Concept.Setting[App.Concept.ConceptName.enum.Action];
  const actionScheduled = App.Concept.Setting[App.Concept.ConceptName.enum.ActionScheduled];
  const actionRepeating = App.Concept.Setting[App.Concept.ConceptName.enum.ActionFrequencyRepeating];
  const actionsPlan = App.Concept.Setting[App.Concept.ConceptName.enum.ActionsPlan];
  const value = App.Concept.Setting[App.Concept.ConceptName.enum.Value];

  export const Step = [
    {
      name: StepName.enum.WeAreWhatWeDo,
      text: [
        `Your <strong>${action.labelPlural}</strong> have <strong>${value.label}</strong>.`,
      ],
      shapes: [
        {
          type: ShapeType.enum.Backdrop,
          appendIcons: [
            App.Concept.ConceptName.enum.Value,
          ],
        },
      ],
    },
    {
      name: StepName.enum.Intro,
      text: [
        `%appName% helps you to be organized and aware of your <strong>${action.labelPlural}</strong>&mdash;how you spend your time and energy.`,
        `Use it to track your <strong>${action.labelPlural}</strong> and to help yourself be more engaged with your goals.`,
      ],
      shapes: [
        {
          type: ShapeType.enum.Rectangle,
          color: 'rgb(0 0 0 / 0.75)',
          label: '',
          appendIcons: [
            App.Concept.ConceptName.enum.Idea,
            App.Concept.ConceptName.enum.Effect,
            App.Concept.ConceptName.enum.Search,
            App.Concept.ConceptName.enum.User,
            App.Concept.ConceptName.enum.Objective,
            App.Concept.ConceptName.enum.SystemUpgrades,
          ],
        },
        {
          type: ShapeType.enum.Rectangle,
          color: 'rgb(0 0 0 / 0.75)',
          label: '',
          appendIcons: [
            App.Concept.ConceptName.enum.ResourceRadia,
            App.Concept.ConceptName.enum.ResourceAether,
            App.Concept.ConceptName.enum.ResourceShade,
          ],
        },
      ],
    },
    {
      name: StepName.enum.ActionsPlan,
      text: [
        `In %appName%, each day is known as a <strong>${actionsPlan.label}</strong>. The ${action.labelPlural} in your ${actionsPlan.label}&mdash;its conditions for success&mdash;are entirely determined by you.`,
        `Succeeding in your ${actionsPlan.label} will present you with an eclipse, one of nature’s great spectacles.`,
      ],
      shapes: [
        {
          type: ShapeType.enum.Oval,
          color: 'var(--sl-color-yellow-500)',
          label: `Start ${_.startCase(actionsPlan.label)}`,
          appendIcons: [
            App.Concept.ConceptName.enum.ActionsPlan,
          ],
        },
      ],
    },
    {
      name: StepName.enum.Actions,
      text: [
        `<strong>${_.startCase(action.labelPlural)}</strong> are the basic building blocks of %appName%. They can represent anything you want to keep track of: to-dos, habits, meetings, errands, reminders, and so much more.`,
        'The point is to get things out of your head and into a central place where your effort can be better understood.',
      ],
      shapes: [
        {
          type: ShapeType.enum.Diamond,
          color: 'var(--sl-color-orange-500)',
          label: 'What can I do?',
        },
      ],
    },
    {
      name: StepName.enum.AddAnAction,
      text: [
        `<strong>${_.startCase(action.labelPlural)}</strong> usually come in two flavors:`,
        `<strong>&lsaquo;${_.startCase(actionRepeating.labelPlural)}&rsaquo;</strong> are kind of like habits&mdash;they're automatically added to your ${actionsPlan.label} when relevant.`,
        `<strong>&lsaquo;${_.startCase(actionScheduled.labelPlural)}&rsaquo;</strong> occur only once and are given a due date. They're added to your mission when they're due.`,
      ],
      shapes: [
        {
          type: ShapeType.enum.Rectangle,
          color: 'var(--sl-color-blue-500)',
          label: 'Add an Action',
          prependIcons: [
            App.Concept.ConceptName.enum.ActionAdd,
          ],
        },
      ],
    },
    {
      name: StepName.enum.Value,
      text: [
        `Remember, every <strong>${action.label}</strong> you take has <strong>&lsaquo;${value.label}&rsaquo;</strong>.`,
        `In %appName%, an ${action.label}’s <strong>${value.label}</strong> is derived from its <strong>importance</strong>, and the <strong>effort</strong> required to complete it.`,
      ],
      shapes: [
        {
          type: ShapeType.enum.Rectangle,
          color: 'var(--sl-color-success-500)',
          label: 'Complete an Action',
          prependIcons: [
            App.Concept.ConceptName.enum.ActionComplete,
          ],
        },
        {
          type: ShapeType.enum.Rectangle,
          color: 'var(--sl-color-success-500)',
          label: 'Get Value',
          prependIcons: [
            App.Concept.ConceptName.enum.Value,
          ],
        },
      ],
    },
    {
      name: StepName.enum.Auth,
      text: [
        'There\'s much more to learn about %appName% and about yourself. Great journeys lie ahead!',
        `Sign up or log in to complete this ${actionsPlan.label}.`,
      ],
      shapes: [
        {
          type: ShapeType.enum.Invisible,
        },
      ],
    },
  ];
}
