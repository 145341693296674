import { render, staticRenderFns } from "./intro.vue?vue&type=template&id=6718d72e&"
import script from "./intro.vue?vue&type=script&lang=js&"
export * from "./intro.vue?vue&type=script&lang=js&"
import style0 from "./intro.vue?vue&type=style&index=0&id=6718d72e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/opt/build/repo/components/icon.vue').default,AppConceptButton: require('/opt/build/repo/components/app-concept-button.vue').default})
